import React, { Component } from 'react';
import { Grid, Cell, Tab, Tabs, IconToggle } from 'react-mdl';
import SickKids from '../assets/SickKids.jpg';
import sunnybrook from '../assets/sunnybrook.png';
import ryan_sunny from '../assets/ryan-drawing.png';
import baylis from '../assets/baylis_heart.jpg';
import swiss_flag from '../assets/swiss_flag.png';
import dnd from '../assets/dnd2.png';
import clinic from '../assets/clinic2.png';
//import blister from '../assets/pills.jpg';
import basc from '../assets/ryan-basc.jpg';
import masc from '../assets/ryan-masc.jpg';
import waterloo from '../assets/ryan-TA.png';
import rangers from '../assets/rangers.jpg';

import CVPDF from '../assets/CV_Ryan-Tennant_06-12-2024.pdf';
//import { useInView } from 'react-intersection-observer';

//import ScrollAnimation from 'react-animate-on-scroll';

import { motion } from 'framer-motion';

class Resume extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            activeTab: 0,

            open_phd_awards: 'Open',
            open_phd_news: 'Open',
            open_phd_publications: 'Open',
            chevronType_phd_publications: 'expand_more',
            chevronType_phd_news: 'expand_more',
            chevronType_phd_awards: 'expand_more',

            open_masc_awards: 'Open',
            chevronType_masc_awards: 'expand_more',
            open_masc_conferences: 'Open',
            chevronType_masc_conferences: 'expand_more',
            open_masc_other: 'Open',
            chevronType_masc_other: 'expand_more',
            open_masc_publications: 'Open',
            chevronType_masc_publications: 'expand_more',

            open_basc_awards: 'Open',
            chevronType_basc_awards: 'expand_more',
            open_basc_conferences: 'Open',
            chevronType_basc_conferences: 'expand_more',
            open_basc_patents: 'Open',
            chevronType_basc_patents: 'expand_more',

            open_teaching_credential: 'Open',
            chevronType_teaching_credentials: 'expand_more',
            open_teaching_courses: 'Open',
            chevronType_teaching_courses: 'expand_more',

            open_teaching_courses_R: 'Open',
            chevronType_teaching_courses_R: 'expand_more',
            
         };
    }

    openList_credentials = () => {
        
        if (this.state.open_teaching_credential === 'Open') {
            this.setState({
                open_teaching_credential: 'Close',
                chevronType_teaching_credentials: 'expand_less',
            });
        }
        
        else {
            this.setState({
                open_teaching_credential: 'Open',
                chevronType_teaching_credentials: 'expand_more'
            })
        }
    }

    openList_courses = () => {
        
        if (this.state.open_teaching_courses === 'Open') {
            this.setState({
                open_teaching_courses: 'Close',
                chevronType_teaching_courses: 'expand_less',
            });
        }
        
        else {
            this.setState({
                open_teaching_courses: 'Open',
                chevronType_teaching_courses: 'expand_more'
            })
        }
    }

    openList_courses_R = () => {
        
        if (this.state.open_teaching_courses_R === 'Open') {
            this.setState({
                open_teaching_courses_R: 'Close',
                chevronType_teaching_courses_R: 'expand_less',
            });
        }
        
        else {
            this.setState({
                open_teaching_courses_R: 'Open',
                chevronType_teaching_courses_R: 'expand_more'
            })
        }
    }

    openList = () => {
        
        if (this.state.open_phd_awards === 'Open') {
            this.setState({
                open_phd_awards: 'Close',
                chevronType_phd_awards: 'expand_less',
            });
        }
        
        else {
            this.setState({
                open_phd_awards: 'Open',
                chevronType_phd_awards: 'expand_more'
            })
        }
    }

    openList_news = () => {
        
        if (this.state.open_phd_news === 'Open') {
            this.setState({
                open_phd_news: 'Close',
                chevronType_phd_news: 'expand_less',
            });
        }
        
        else {
            this.setState({
                open_phd_news: 'Open',
                chevronType_phd_news: 'expand_more'
            })
        }
    }

    openList2 = () => {
        if (this.state.open_masc_awards === 'Open') {
            this.setState({
                open_masc_awards: 'Close',
                chevronType_masc_awards: 'expand_less',
            });
        }
        
        else {
            this.setState({
                open_masc_awards: 'Open',
                chevronType_masc_awards: 'expand_more'
            })
        }
    }

    openList3 = () => {

        if (this.state.open_basc_awards === 'Open') {
            this.setState({
                open_basc_awards: 'Close',
                chevronType_basc_awards: 'expand_less',
            });
        }
        
        else {
            this.setState({
                open_basc_awards: 'Open',
                chevronType_basc_awards: 'expand_more'
            })
        }
    }

    openList4 = () => {

        if (this.state.open_basc_patents === 'Open') {
            this.setState({
                open_basc_patents: 'Close',
                chevronType_basc_patents: 'expand_less',
            });
        }
        
        else {
            this.setState({
                open_basc_patents: 'Open',
                chevronType_basc_patents: 'expand_more'
            })
        }
    }

    openList5 = () => {

        if (this.state.open_basc_conferences === 'Open') {
            this.setState({
                open_basc_conferences: 'Close',
                chevronType_basc_conferences: 'expand_less',
            });
        }
        
        else {
            this.setState({
                open_basc_conferences: 'Open',
                chevronType_basc_conferences: 'expand_more'
            })
        }
    }

    openList6 = () => {

        if (this.state.open_masc_other === 'Open') {
            this.setState({
                open_masc_other: 'Close',
                chevronType_masc_other: 'expand_less',
            });
        }
        
        else {
            this.setState({
                open_masc_other: 'Open',
                chevronType_masc_other: 'expand_more'
            })
        }
    }

    openList7 = () => {

        if (this.state.open_masc_publications === 'Open') {
            this.setState({
                open_masc_publications: 'Close',
                chevronType_masc_publications: 'expand_less',
            });
        }
        
        else {
            this.setState({
                open_masc_publications: 'Open',
                chevronType_masc_publications: 'expand_more'
            })
        }
    }

    openList8 = () => {

        if (this.state.open_masc_conferences === 'Open') {
            this.setState({
                open_masc_conferences: 'Close',
                chevronType_masc_conferences: 'expand_less',
            });
        }
        
        else {
            this.setState({
                open_masc_conferences: 'Open',
                chevronType_masc_conferences: 'expand_more'
            })
        }
    }

    openList9 = () => {

        if (this.state.open_phd_publications === 'Open') {
            this.setState({
                open_phd_publications: 'Close',
                chevronType_phd_publications: 'expand_less',
            });
        }
        
        else {
            this.setState({
                open_phd_publications: 'Open',
                chevronType_phd_publications: 'expand_more'
            })
        }
    }

    teaching_credentials = () => {

        if(this.state.open_teaching_credential === 'Open') {
            return(
                null
            )
        }
        else{
            return(
                <ul style={{marginLeft: 22.5, paddingTop: 15}}>
                    <li>
                        EQ102 - 2SLGBTQ+ Fundamentals
                    </li>
                    <li>
                        EQ206 - Disrupting and Decentering Whiteness
                    </li>
                    <li>
                        CTE Fundamentals of University Teaching (certificate)
                    </li>
                    <li>
                        CTE Microteaching Session #3 - Favourite Teaching Tip: Incorporating Universal Design Strategies to Create Inclusive Instruction Environments (Evaluation: Accepted)
                    </li>
                    <li>
                        CTE Microteaching Session #2 - Dalton's Atomic Theory (Evaluation: Accepted)
                    </li>
                    <li>
                        CTE Microteaching Session #1 - Engineering Analysis (Evaluation: Accepted)
                    </li>
                    <li>
                        CEL Getting Ready to Facilitate Online Courses Training (certificate)
                    </li>
                    <li>
                        CTE2250 - Blended Learning for TAs
                    </li>
                    <li>
                        CTE1176 - Shaping Classroom Dynamics
                    </li>
                    <li>
                        CTE0219 - Special Topics in Teaching
                    </li>
                    <li>
                        EQ 101 - Introduction to Equity
                    </li>
                    <li>
                        CTE1111 - Teaching Tutorials in STEM Fields
                    </li>
                    <li>
                        CTE1260 - Interactive Lectures
                    </li>
                    <li>
                        CTE1263 - Academic Integrity for TAs
                    </li>
                    <li>
                        CTE1406 - TA Facilitation in a Remote Teaching Environment
                    </li>
                    <li>
                        CTE2268 - Teaching Practices to Cultivate Well-Being and Compassion [Inclusive Instruction Series]
                    </li>
                </ul>)
        }
        
    }

    teaching_courses = () => {

        if(this.state.open_teaching_courses === 'Open') {
            return(
                null
            )
        }
        else{
            return(
                <ul style={{marginLeft: 22.5, paddingTop: 15}}>
                    <li>
                        BME 162 (TA) - Human Factors in the Design of Biomedical and Health Systems (Winter 2023)
                    </li>
                    <ul>
                        <li>
                            Providing quality-based feedback on the grading of written group assignments and examinations to a class of 100 students.
                        </li>
                        <li>
                            Facilitated case study workshops on signal detection theory.
                        </li>
                        <li>
                            Designed and facilitated a new workshop on link analysis in the context of mass vaccination clinics.
                        </li>
                    </ul>
                    <li>
                        BME 362 (TA) - Design Workshop 1 (Fall 2022, Fall 2023)
                    </li>
                    <ul>
                        <li>
                            Medical device regulatory pathways workshop facilitation.
                        </li>
                        <li>
                            Quality-based grading of assignments and tests.
                        </li>
                    </ul>
                     <li>
                        BME 162 (TA) - Human Factors in Biomedical Engineering (Winter 2022)
                    </li>
                    <ul>
                        <li>
                            Quality-based grading of assignments and tests.
                        </li>
                    </ul>
                    <li>
                        SYDE 361 (TA) - Engineering Design (Spring 2021)
                    </li>
                    <ul>
                        <li>
                            Met with students outside of class to discuss course deliverables as well as interests in pursuing research/career paths.
                        </li>
                        <li>
                            Led engineering design project critique sessions with 10 design teams to support prototype evaluation and iteration.
                        </li>
                    </ul>
                    <li>
                        BME 186 (TA) - Chemistry Principles (Winter 2020, Winter 2021)
                    </li>
                    <ul>
                        <li>
                            Led the creation of updated assignments and solution manuals that include real-world applications of chemistry principles in biomedical engineering.
                        </li>
                        <li>
                            Created a midterm study package to provide students with supplementary practice questions.
                        </li>
                        <li>
                            Coordinated the creation of the midtern exam and solution manual with the Teaching Assistant team.
                        </li>
                    </ul>
                    <li>
                        SYDE 162 (TA) - Human Factors in Design (Spring 2020)
                    </li>
                    <ul>
                        <li>
                            Quality-based grading of assignments.
                        </li>
                    </ul>
                    <li>
                        BME 161 (TA) - Introduction to Biomedical Design (Fall 2020)
                    </li>
                    <ul>
                        <li>Provided support for 10 design teams through weekly meetings to discuss progress, deliverables, and answer questions.</li>
                        <li>Supported students beyond the course content to help with transitioning to university in an online setting.</li>
                        <li>Nominated for a Teaching Assistant award.</li>
                    </ul>
                </ul>)
        }
        
    }

    teaching_courses_R = () => {

        if(this.state.open_teaching_courses_R === 'Open') {
            return(
                null
            )
        }
        else{
            return(
                <ul style={{marginLeft: 22.5, paddingTop: 15}}>
                    <li>
                        MHF 4UI (Tutor) - Grade 12 Advanced Functions
                    </li>
                    <li>
                        SCH 4UI (Tutor) - Grade 12 Chemistry
                    </li>
                    <li>
                        MCR 3UI (Tutor) - Grade 11 Functions
                    </li>
                </ul>)
        }
        
    }

    awards_phd = () => {

        if(this.state.open_phd_awards === 'Open') {
            return(
                null
            )
        }
        else{
            return(
                <ul style={{marginLeft: 22.5, paddingTop: 15}}>
                <li>
                    2023 - <a style = {{textDecoration: 'underline', color: '#000'}} href="https://www.nserc-crsng.gc.ca/students-etudiants/pg-cs/cgsd-bescd_eng.asp" rel="noopener noreferrer" target="_blank">NSERC Canada Graduate Scholarships - Doctoral Program (CGS-D)</a>
                </li>
                <li>
                    2023 - <a style = {{textDecoration: 'underline', color: '#000'}} href="https://uwaterloo.ca/engineering/graduate-students/funding-and-awards/fellowships-other-major-awards" rel="noopener noreferrer" target="_blank">Faculty of Engineering CGS-D Match</a>
                </li>
                <li>
                    2023 - <a style = {{textDecoration: 'underline', color: '#000'}} href="https://uwaterloo.ca/graduate-studies-postdoctoral-affairs/current-students/internal-waterloo-awards/presidents-graduate-scholarship" rel="noopener noreferrer" target="_blank">President's Graduate Scholarship CGS-D Match</a>
                </li>
                <li>
                    2021 - <a style = {{textDecoration: 'underline', color: '#000'}} href="https://uwaterloo.ca/graduate-studies-postdoctoral-affairs/awards/engineering-excellence-masters-and-doctoral-fellowships-eemf" rel="noopener noreferrer" target="_blank">Engineering Excellence Doctoral Fellowship</a>
                </li>
                <li>
                    2021 - <a style = {{textDecoration: 'underline', color: '#000'}} href="https://uwaterloo.ca/graduate-studies-postdoctoral-affairs/awards/engineering-deans-entrance-award" rel="noopener noreferrer" target="_blank">Engineering Dean's Entrance Award</a>
                </li>
                <li>
                    2021 - <a style = {{textDecoration: 'underline', color: '#000'}} href="https://uwaterloo.ca/graduate-studies-postdoctoral-affairs/current-students/internal-waterloo-awards/presidents-graduate-scholarship" rel="noopener noreferrer" target="_blank">President's Graduate Scholarship</a>
                </li>
                <li>
                    2021 - <a style = {{textDecoration: 'underline', color: '#000'}} href="https://osap.gov.on.ca/OSAPPortal/en/A-ZListofAid/PRDR019236.html" rel="noopener noreferrer" target="_blank">Queen Elizabeth II Graduate Scholarship in Science and Technology (QEII-GSST)</a>
                </li>
            </ul>)
        }
        
    }

    publications_phd = () => {
        if(this.state.open_phd_publications === 'Open') {
            return(
                null
            )
        }
        else{
            return(
                <ol>
                    <li>
                        <b>Tennant R</b>, Graham J, Kern J, Mercer K, Ansermino JM, & Burns CM. A scoping review on pediatric sepsis prediction technologies in healthcare. <i>npj Digital Medicine.</i> 2024;7(353) <a style = {{textDecoration: 'underline', color: '#000'}} href="https://www.nature.com/articles/s41746-024-01361-9" rel="noopener noreferrer" target="_blank">DOI: 10.1038/s41746-024-01361-9</a>
                    </li>
                    <li>
                        Randall T, Hunter A, & <b>Tennant R</b>. Evaluation of geospatial information integration for naval planning. <i>Defence Research and Development Canada Scientific Reports.</i> 2023;11 <a style = {{textDecoration: 'underline', color: '#000'}} href="https://cradpdf.drdc-rddc.gc.ca/PDFS/unc454/p817457_A1b.pdf" rel="noopener noreferrer" target="_blank">DRDC-RDDC-2023-R138</a>
                    </li>
                    <li>
                        Tetui M, <b>Tennant R</b>, Adil M, Bala A, Burns CM, Waite N, & Grindrod K. “Flying a plane and building it at the same time”: Lessons learned from the dynamic implementation of mass vaccination clinics in the Region of Waterloo, Ontario, Canada. <i>Health Research Policy and Systems.</i> 2023;21(1):102 <a style = {{textDecoration: 'underline', color: '#000'}} href="https://doi.org/10.1186/s12961-023-01036-z" rel="noopener noreferrer" target="_blank">DOI: 10.1186/s12961-023-01036-z</a>
                    </li>
                    <li>
                        Tetui M, <b>Tennant R</b>, Patten A, Giilck B, Burns CM, Waite N, & Grindrod K. Role satisfaction among community volunteers working in mass COVID-19 vaccination clinics, Waterloo Region, Canada. <i>BMC Public Health.</i> 2023;23(1):1199 <a style = {{textDecoration: 'underline', color: '#000'}} href="https://doi.org/10.1186/s12889-023-15597-9" rel="noopener noreferrer" target="_blank">DOI: 10.1186/s12889-023-15597-9</a>
                    </li>
                    <li>
                        <b>Tennant R</b>, Tetui M, Grindrod K, & Burns CM. Multi-Disciplinary Design and Implementation of a Mass Vaccination Clinic Mobile Application to Support Decision-Making. <i>IEEE Journal of Translational Engineering in Health and Medicine.</i> 2022;11, pp. 60-69 <a style = {{textDecoration: 'underline', color: '#000'}} href="https://doi.org/10.1109/JTEHM.2022.3224740" rel="noopener noreferrer" target="_blank">DOI: 10.1109/JTEHM.2022.3224740</a>
                    </li>
                    <li>
                        <b>Tennant R</b>, Graham J, Mercer K, Ansermino JM, & Burns CM. Automated digital technologies for supporting sepsis prediction in children: A scoping review protocol. <i>BMJ Open.</i> 2022;12:e065429 <a style = {{textDecoration: 'underline', color: '#000'}} href="https://doi.org/10.1136/bmjopen-2022-065429" rel="noopener noreferrer" target="_blank">DOI: 10.1136/bmjopen-2022-065429</a>
                    </li>
                    <li>
                        <b>Tennant R</b>, Tetui M, Grindrod K, & Burns CM. Understanding Human Factors Challenges on the Front Lines of Mass COVID-19 Vaccination Clinics: Human-Systems Modelling Study. <i>JMIR Human Factors.</i> 2022;9(4):e39670 <a style = {{textDecoration: 'underline', color: '#000'}} href="https://doi.org/10.2196/39670" rel="noopener noreferrer" target="_blank">DOI: 10.2196/39670</a>
                    </li>
                </ol>)
        }
    }


    news_phd = () => {

        if(this.state.open_phd_news === 'Open') {
            return(
                null
            )
        }
        else{
            return(
            <ul style={{marginLeft: 22.5, paddingTop: 15}}>
                <li>
                    2023 - <a style = {{textDecoration: 'underline', color: '#000'}} href="https://www.therecord.com/opinion/universities-can-lead-the-clean-indoor-air-revolution/article_bcfca1dc-17e9-599c-9b6c-1501e85eb0af.html" rel="noopener noreferrer" target="_blank">"Universities can lead the clean indoor air revolution"</a>
                </li>
                <li>
                    2023 - <a style = {{textDecoration: 'underline', color: '#000'}} href="https://uwaterloo.ca/news/science/every-shot-gold" rel="noopener noreferrer" target="_blank">"Every shot, a shot of gold"</a>
                </li>
                <li>
                    2022 - <a style = {{textDecoration: 'underline', color: '#000'}} href="https://uwaterloo.ca/news/global-impact/pharmacies-lead-way-digital-technologies-improve-patient" rel="noopener noreferrer" target="_blank">"Pharmacies lead the way with digital technologies to improve patient care"</a>
                </li>
                <li>
                    2021 - <a style = {{textDecoration: 'underline', color: '#000'}} href="https://uwaterloo.ca/news/running-vaccine-clinic-theres-app" rel="noopener noreferrer" target="_blank">"Running a vaccine clinic? There's an app for that"</a>
                </li>
            </ul>)
        }
        
    }

    awards_masc = () => {

        if(this.state.open_masc_awards === 'Open') {
            return(
                null
            )
        }
        else{
            return(
                <ul style={{marginLeft: 22.5, paddingTop: 15}}>
                    <li>
                        2021 - <a style = {{textDecoration: 'underline', color: '#000'}} href="https://www.youtube.com/watch?v=kdIvUYyuqX0" rel="noopener noreferrer" target="_blank">UW GSPA GRADflix - Finalist</a>
                    </li>
                    <li>
                        2020 - <a style = {{textDecoration: 'underline', color: '#000'}} href="https://uwaterloo.ca/graduate-studies-postdoctoral-affairs/current-students/internal-waterloo-awards/presidents-graduate-scholarship" rel="noopener noreferrer" target="_blank">President's Graduate Scholarship</a>
                    </li>
                    <li>
                        2020 - <a style = {{textDecoration: 'underline', color: '#000'}} href="https://uwaterloo.ca/graduate-studies-postdoctoral-affairs/awards/uw-alumni-microsoft-graduate-scholarship" rel="noopener noreferrer" target="_blank">UW Alumni @ Microsoft Scholarship</a>
                    </li>
                    <li>
                        2020 - <a style = {{textDecoration: 'underline', color: '#000'}} href="https://osap.gov.on.ca/OSAPPortal/en/A-ZListofAid/PRDR019245.html" rel="noopener noreferrer" target="_blank">Ontario Graduate Scholarship (OGS)</a>
                    </li>
                    <li>
                        2020 - Johns Hopkins Healthcare Design Competition - Finalist
                    </li>
                    <li>
                        2019 - <a style = {{textDecoration: 'underline', color: '#000'}} href="https://99scholarships.ca/" rel="noopener noreferrer" target="_blank">ReThink | ReBoot - 99 Scholarships Program</a>
                    </li>
                    <li>
                        2019 - Medventions Entrepreneurs Award
                    </li>
                    <li>
                        2019 - <a style = {{textDecoration: 'underline', color: '#000'}} href="https://uwaterloo.ca/graduate-studies-postdoctoral-affairs/awards/engineering-deans-entrance-award" rel="noopener noreferrer" target="_blank">Engineering Dean's Entrance Award</a>
                    </li>
                    <li>
                        2019 - <a style = {{textDecoration: 'underline', color: '#000'}} href="https://uwaterloo.ca/conrad-school-entrepreneurship-business/graduate-students/master-business-entrepreneurship-and-technology/tuition-scholarships-and-financial-aid/scholarships" rel="noopener noreferrer" target="_blank">The Norman Esch Entrepreneurship Scholarship - Declined</a>
                    </li>
                </ul>)
        }
        
    }

    conferences_masc = () => {
        if(this.state.open_masc_conferences === 'Open') {
            return(
                null
            )
        }
        else{
            return(
                <ol>
                    <li>
                        Faisal S, Ivo J, <b>Tennant R</b>, Prior KA, McMillan C, Grindrod K, Patel T. Implementation of a smart adherence technology intervention in community pharmacy settings: A qualitative study. <i>2021 ACCP Annual Meeting Virtual Poster Symposium Abstract Archive</i>. 2021 ACCP Virtual Poster Symposium. 2021.
                    </li>
                    <li>
                        Faisal S, Ivo J, <b>Tennant R</b>, Prior KA, McMillan C, Grindrod K, Patel T. Integration of a smart adherence device by older adults with chronic diseases - An ethnographic study. <i>Journal of the American Geriatrics Society</i>. 2021 Virtual Annual Scientific Meeting of the American Geriatrics Society. 2021.
                    </li>
                    <li>
                        <b>Tennant R</b>, Leggett A, Smith C, Tan L, Mercer K, & Burns CM. (2020). Addressing Human Factor Challenges in Paediatric Home Care: Development and Evaluation of a Mobile Home Care Communication App. <i>Proceedings of the International Symposium on Human Factors and Ergonomics in Health Care</i>, 9(1), 37–39. <a style = {{textDecoration: 'underline', color: '#000'}} href="https://doi.org/10.1177/2327857920091051" rel="noopener noreferrer" target="_blank">DOI: 10.1177/2327857920091051</a>
                    </li>
                </ol>)
        }
    }

    other_masc = () => {
        if(this.state.open_masc_other === 'Open') {
            return(
                null
            )
        }
        else{
            return(
                <ol>
                    <li>
                        (2021). A novel re-entry catheter for angioplasty of coronary and peripheral chronic total occlusions. <i>The Canadian-Israel Cardiovascular Innovation Forum.</i> Toronto, Canada. (podium)
                    </li>
                    <li>
                        (2021). Exploring Information Management and Health Communication in Complex Home Care: A Qualitative Study. <i>2021 Inter-University Workshop.</i> Buffalo, United States (podium)
                    </li>
                    <li>
                        (2020). A Mobile Communication App for Paediatric Home Care. <i>Johns Hopkins Healthcare Design Competition.</i> Baltimore, United States. (podium)
                    </li>
                    <li>
                        (2020). Stellar Care Mobile App. <i>Norman Esch Entrepreneurship Awards for Capstone Design.</i> Waterloo, Canada. (podium)
                    </li>
                    <li>
                        (2019). A comparative task analysis between a developed mHealth communication app and current healthcare tracking tools for families of children with medical complexities who receive home care services. <i>2019 Inter-University Workshop.</i> (poster)
                    </li>
                </ol>)
        }
    }

    publications_masc = () => {
        if(this.state.open_masc_publications === 'Open') {
            return(
                null
            )
        }
        else{
            return(
                <ol>
                    <li>
                        <b>Tennant R</b>, Allana S, Mercer K, & Burns CM. Caregiver Expectations of Interfacing With Voice Assistants to Support Complex Home Care: Mixed Methods Study. <i>JMIR Human Factors</i>. 2022;9(2):e37688 <a style = {{textDecoration: 'underline', color: '#000'}} href="https://doi.org/10.2196/37688" rel="noopener noreferrer" target="_blank">DOI: 10.2196/37688</a></li>
                    <li>
                        Faisal S, Ivo J, <b>Tennant R</b>, Prior KA, Grindrod K, McMillan C, & Patel T. Integration of a smart multidose blister package for medication intake: A mixed method ethnographic informed study of older adults with chronic diseases. <i>PLOS ONE</i>. 2022;17(1):e0262012. <a style = {{textDecoration: 'underline', color: '#000'}} href="https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0262012" rel="noopener noreferrer" target="_blank">DOI: 10.1371/journal.pone.0262012</a>
                    </li>
                    <li>
                        <b>Tennant R</b>, Allana S, Mercer K, & Burns CM. Exploring the Experiences of Family Caregivers of Children With Special Health Care Needs to Inform the Design of Digital Health Systems: Formative Qualitative Study. <i>JMIR Formative Research</i>. 2022;6(1):e28895 <a style = {{textDecoration: 'underline', color: '#000'}} href="https://formative.jmir.org/2022/1/e28895" rel="noopener noreferrer" target="_blank">DOI: 10.2196/28895</a>
                    </li>
                    <li>
                        Faisal S, Ivo J, <b>Tennant R</b>, Prior KA, Grindrod K, McMillan C, & Patel T. Implementation of a real-time medication intake monitoring technology intervention in community pharmacy settings: A mixed-method pilot study. <i>Pharmacy</i>. 2021;9(2):105. <a style = {{textDecoration: 'underline', color: '#000'}} href="https://doi.org/10.3390/pharmacy9020105" rel="noopener noreferrer" target="_blank">DOI: 10.3390/pharmacy90201055</a>
                    </li>
                    <li>
                        Mercer K, Carter C, Burns C, <b>Tennant R</b>, & Grindrod KA. Including the reason for use on prescriptions sent to pharmacists: A scoping review. <i>JMIR Human Factors</i>. 2021;8(4):e22325. <a style = {{textDecoration: 'underline', color: '#000'}} href="https://doi.org/10.2196/22325" rel="noopener noreferrer" target="_blank">DOI: 10.2196/22325</a>
                    </li>
                </ol>)
        }
    }

    awards_basc = () => {

        if(this.state.open_basc_awards === 'Open') {
            return(
                null
            )
        }
        else{
            return(
                <ul style={{marginLeft: 22.5, paddingTop: 15}}>
                    <li>
                        2019 - Best Overall Capstone Design Project Award for BME 2019
                    </li>
                    <li>
                        2019 - <a style = {{textDecoration: 'underline', color: '#000'}} href="https://uwaterloo.ca/capstone-design/norman-esch-entrepreneurship-awards-capstone-design" rel="noopener noreferrer" target="_blank">Norman Esch Entrepreneuship Award for Capstone Design</a>
                    </li>
                    <li>
                        2019 - <a style = {{textDecoration: 'underline', color: '#000'}} href="https://www.youtube.com/watch?v=o3uPSR0nkOc" rel="noopener noreferrer" target="_blank">Velocity Fund 5K Finals - Finalist</a>
                    </li>
                    <li>
                        2015 - <a style = {{textDecoration: 'underline', color: '#000'}} href="https://uwaterloo.ca/undergraduate-entrance-awards/awards/university-waterloo-presidents-scholarship-distinction" rel="noopener noreferrer" target="_blank">University of Waterloo President's Scholarship of Distinction</a>
                    </li>
                    <li>
                        2014 - <a style = {{textDecoration: 'underline', color: '#000'}} href="https://rangersreach.com/programs/scholarships/" rel="noopener noreferrer" target="_blank">Kitchener Rangers Entrance Scholarship</a>
                    </li>
                    <li>
                        2014 - <a style = {{textDecoration: 'underline', color: '#000'}} href="https://www.execulink.ca/community/scholarship-program/" rel="noopener noreferrer" target="_blank">Execulink Telecom Entrance Scholarship</a>
                    </li>
                </ul>)
        }
        
    }

    conferences_basc = () => {
        if(this.state.open_basc_conferences === 'Open') {
            return(
                null
            )
        }
        else{
            return(
                <ol>
                    <li>
                        Looi T, <b>Tennant R</b>, Constantiel-Collas E, Pichardo S, Waspe A, Hynynen K, & Drake, JM. (2018). Pressure Characterization for Transcranial Sonication of a Neonatal Skull. <i>18th International Symposium on Therapeutic Ultrasound (ISTU).</i>
                    </li>
                </ol>
            )
        }
    }

    patents_basc = () => {
        if(this.state.open_basc_patents === 'Open') {
            return(
                null
            )
        }
        else{
            return(
                <ol>
                    <li>
                    <b>Tennant R</b>, Di Bartolomeo L, Strauss BH, Abuzeid W, & Ardeshiri R. Catheter Device For Lumen Re-Entry And Methods For Use Thereof. Applicant: Sunnybrook Research Institute. International Publication. <a style = {{textDecoration: 'underline', color: '#000'}} href="https://patents.google.com/patent/WO2019046976A1/en?oq=WO2019046976" rel="noopener noreferrer" target="_blank">WO 2019/046976</a>
                    </li>
                </ol>
            )
        }
    }

    toggleCategories(){

        /*WORK*/
        if(this.state.activeTab === 0){
            return(
                <div>
                <Grid className="landing" style = {{paddingBottom: '0%', paddingLeft: '5%', paddingRight: '5%', background: '#fff'}}>
                   <Cell col={5} phone={12} tablet={12} style = {{alignSelf: 'center'}}>
                        <motion.div initial={{ opacity: 0, x: 100}}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 1 }}>
                            <img
                            //src="https://uwaterloo.ca/advanced-interface-design-lab/sites/ca.advanced-interface-design-lab/files/uploads/images/ryan.jpg"
                            src={dnd}
                            alt="Ryan Tennant"
                            className="avatar-img"
                            />
                        </motion.div>
                    </Cell>
                    <Cell col={6} phone={12} tablet={12} style = {{alignSelf: 'center'}}>
                        <motion.div className="banner-text-white" initial={{ opacity: 0, x: 100}}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 1 }} 
                            style = {{flex: 1, textAlign: 'left', marginTop: 0, marginBottom: 0}}>
                                
                                <h4 style = {{paddingBottom: 0, fontWeight: 'bold'}}>
                                    Human Factors Graduate Student Intern - Research Affiliate Program
                                </h4>
                                <hr style = {{height: 5, color: '#000',
                                        backgroundColor: '#000',}}>
                                </hr>
                                <a style = {{textDecoration: 'underline', color: '#000'}} href="https://www.canada.ca/en/defence-research-development/services/capabilities.html#toc0" rel="noopener noreferrer" target="_blank">
                                    <h5 style = {{paddingBottom: 10}}>
                                        Defence Research and Development Canada
                                    </h5>
                                </a>
                                <h5 style = {{paddingBottom: 10}}>
                                    Halifax, Nova Scotia
                                </h5>
                                <h5 style = {{paddingBottom: 10}}>
                                    July 2021 - December 2022
                                </h5>
                                <h5 style = {{paddingBottom: 10}}>
                                    October 2023 - December 2025
                                </h5>
    
                            <p style = {{paddingTop: 10}}>
                                For DRDC Atlatnic, I conducted a scoping review on <b>uncertainty visualization</b>, specifically in the context of geo-spatial information to support decision-making and inform interface design for the Atlantic Research Centre. My responsibilities also included statistical data analysis for human performance measures as a result of interface design characteristics.
                            </p>
                            
                            <p style = {{paddingTop: 10}}>
                                <b>Literauture Review</b> | <b>Visualization</b> | <b>Decision-Making</b> | <b>Uncertainty</b>
                            </p>
                        </motion.div>
                    </Cell> 
             
                </Grid>

                <Grid className="landing" style = {{paddingBottom: '0%', paddingLeft: '5%', paddingRight: '5%', background: '#efefef'}}>
                   <Cell col={5} phone={12} tablet={12} style = {{alignSelf: 'center'}}>
                        <motion.div initial={{ opacity: 0, x: -100}}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 1 }}>
                            <img
                            //src="https://uwaterloo.ca/advanced-interface-design-lab/sites/ca.advanced-interface-design-lab/files/uploads/images/ryan.jpg"
                            src={swiss_flag}
                            alt="Ryan Tennant"
                            className="avatar-img"
                            />
                        </motion.div>
                    </Cell>
                    <Cell col={6} phone={12} tablet={12} style = {{alignSelf: 'center'}}>
                        <motion.div className="banner-text-white" initial={{ opacity: 0, x: -100}}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 1 }} 
                            style = {{flex: 1, textAlign: 'left', marginTop: 0, marginBottom: 0}}>
                                
                                <h4 style = {{paddingBottom: 0, fontWeight: 'bold'}}>
                                    Biomedical R&D Engineer
                                </h4>
                                <hr style = {{height: 5, color: '#000',
                                        backgroundColor: '#000',}}>
                                </hr>
                                <a style = {{textDecoration: 'underline', color: '#000'}} href="https://www.optotune.com/" rel="noopener noreferrer" target="_blank">
                                    <h5 style = {{paddingBottom: 10}}>
                                        Optotune Ag
                                    </h5>
                                </a>
                                <h5 style = {{paddingBottom: 10}}>
                                    Dietikon, Switzerland
                                </h5>
                                <h5 style = {{paddingBottom: 10}}>
                                    Jan 2018 - Aug 2018
                                </h5>
    
                            <p style = {{paddingTop: 10}}>
                                I was responsible for leading the development of a novel, <b>proof-of-concept medical device</b> in collaboration with an external client. I had the opportunity to work with domain experts in <b>fluid dynamics</b>, <b>mechanical design</b>, <b>magnetism</b> and <b>project management</b> while leading the various stages of concept development. It was an impactful experience both for our client and our team to produce a functioning prototype in eight months.
                            </p>
                            
                            <p style = {{paddingTop: 10}}>
                                <b>SolidWorks</b> | <b>3D Printing</b> | <b>COMSOL</b> | <b>Arduino (C)</b> | <b>Sensors</b> | <b>Polymer Film Fabrication</b>
                            </p>
                        </motion.div>
                    </Cell> 
             
                </Grid>

                <Grid className="landing" style = {{paddingBottom: '0%', paddingLeft: '5%', paddingRight: '5%'}}>
                <Cell col={5} phone={12} tablet={12} style = {{alignSelf: 'center'}}>
                        <motion.div initial={{ opacity: 0, x: 100}}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 1 }}>
                            <img
                            //src="https://uwaterloo.ca/advanced-interface-design-lab/sites/ca.advanced-interface-design-lab/files/uploads/images/ryan.jpg"
                            src={SickKids}
                            alt="Ryan Tennant"
                            className="avatar-img"
                            />
                        </motion.div>
                    </Cell>
                    <Cell col={6} phone={12} tablet={12} style = {{alignSelf: 'center'}}>
                        <motion.div className="banner-text-white" initial={{ opacity: 0, x: 100}}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 1 }}
                            style = {{flex: 1, textAlign: 'left'}}>
                            
                            <h4 style = {{paddingBottom: 0, fontWeight: 'bold'}}>
                                Biomedical R&D Engineer
                            </h4>
                            <hr style = {{height: 5, color: '#000',
                                backgroundColor: '#000',}}>
                            </hr>
                            <a style = {{textDecoration: 'underline', color: '#000'}} href="https://www.cigiti.ca/" rel="noopener noreferrer" target="_blank">
                                <h5 style = {{paddingBottom: 10}}>
                                    The Hospital for Sick Children
                                </h5>
                            </a>
                            <h5 style = {{paddingBottom: 10}}>
                                Toronto, Ontario
                            </h5>
                            <h5 style = {{paddingBottom: 10}}>
                                May 2017 - Aug 2017
                            </h5>
                            <p style = {{paddingTop: 10}}>
                                At the <b>Centre for Image-Guided Innovation and Therapeutic Intervention</b> my primary responsibilities were in the development of a <b>3-axis hydrophone positioning robot</b>. This consisted of systematically evaluating performance measures, identifying and implementing design improvements, and evaluating benchmarks. Specifically, I <b>modified the mechanical components</b> to improve durability, <b>re-wrote the motor control software</b> to improve positioning accuracy and <b>updated the GUI to improve usability</b>.
                            </p>
                            <p style = {{paddingTop: 10}}>
                                <b>MATLAB</b> | <b>Arduino (C)</b> | <b>SolidWorks</b> | <b>3D Printing</b> | <b>Data Analysis</b> | <b>Failure Analysis</b>
                            </p>
                        </motion.div>
                    </Cell>                  
                </Grid>

                <Grid className="landing" style = {{paddingBottom: '0%', paddingLeft: '5%', paddingRight: '5%', background: '#efefef'}}>
                <Cell col={5} phone={12} tablet={12} style = {{alignSelf: 'center'}}>
                        <motion.div initial={{ opacity: 0, x: -100}}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 1 }}>
                            <img
                            //src="https://uwaterloo.ca/advanced-interface-design-lab/sites/ca.advanced-interface-design-lab/files/uploads/images/ryan.jpg"
                            src={sunnybrook}
                            alt="Ryan Tennant"
                            className="avatar-img"
                            />
                        </motion.div>
                    </Cell>
                    <Cell col={6} phone={12} tablet={12} style = {{alignSelf: 'center'}}>
                        <motion.div className="banner-text-white" initial={{ opacity: 0, x: -100}}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 1 }}
                            style = {{flex: 1, textAlign: 'left'}}>
                            
                            <h4 style = {{paddingBottom: 0, fontWeight: 'bold'}}>
                                Medventions Fellow
                            </h4>
                            <hr style = {{height: 5, color: '#000',
                                backgroundColor: '#000',}}>
                            </hr>
                            <a style = {{textDecoration: 'underline', color: '#000'}} href="https://sunnybrook.ca/content/?page=medventions" rel="noopener noreferrer" target="_blank">
                                <h5 style = {{paddingBottom: 10}}>
                                    Sunnybrook Health Sciences Centre
                                </h5>
                            </a>
                            <h5 style = {{paddingBottom: 10}}>
                                Toronto, Ontario
                            </h5>
                            <h5 style = {{paddingBottom: 10}}>
                                Sep 2016 - Dec 2016
                            </h5>
                            <p style = {{paddingTop: 10}}>
                                The objective of the Medventions Fellowship Program is to train engineers and clinicians to identify unmet clinical needs and foster medtech entrepreneurship and innovation in Canada. This experience provided the opportunity to <b>observe cardiovascular procedures</b>, <b>interview physicians and nurses</b>, and <b>prototype a novel medical device</b> to improve procedural outcomes for <b>chronic total occlusions</b> by enhancing guidewire crossing. Our <b><a style = {{textDecoration: 'underline', color: '#000'}} href="https://patentscope.wipo.int/search/en/detail.jsf?docId=WO2019046976" rel="noopener noreferrer" target="_blank">patented</a> microcatheter</b> with multi-national filing is currently in development.
                            </p>
                            <p style = {{paddingTop: 10}}>
                                <b>Clincal Observation</b> | <b>Contextual Inquiry</b> | <b>Usability Testing</b> | <b>SolidWorks</b> | <b>AutoCAD</b> | <b>3D Printing</b> | <b>Hot Air Rework</b>
                            </p>
                        </motion.div>
                    </Cell>                  
                </Grid>

                <Grid className="landing" style = {{paddingBottom: '0%', paddingLeft: '5%', paddingRight: '5%'}}>
                <Cell col={5} phone={12} tablet={12} style = {{alignSelf: 'center'}}>
                        <motion.div initial={{ opacity: 0, x: 100}}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 1 }}>
                            <img
                            //src="https://uwaterloo.ca/advanced-interface-design-lab/sites/ca.advanced-interface-design-lab/files/uploads/images/ryan.jpg"
                            src={baylis}
                            alt="Ryan Tennant"
                            className="avatar-img"
                            />
                        </motion.div>
                    </Cell>
                    <Cell col={6} phone={12} tablet={12} style = {{alignSelf: 'center'}}>
                        <motion.div className="banner-text-white" initial={{ opacity: 0, x: 100}}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 1 }}
                            style = {{flex: 1, textAlign: 'left'}}>
                           
                            <h4 style = {{paddingBottom: 0, fontWeight: 'bold'}}>
                                Biomedical R&D Engineer
                            </h4>
                            <hr style = {{height: 5, color: '#000',
                                    backgroundColor: '#000',}}>
                            </hr>
                            <a style = {{textDecoration: 'underline', color: '#000'}} href="https://www.baylismedical.com/" rel="noopener noreferrer" target="_blank">
                                <h5 style = {{paddingBottom: 10}}>
                                    Baylis Medical Company
                                </h5>
                            </a>
                            <h5 style = {{paddingBottom: 10}}>
                                Mississauga, Ontario
                            </h5>
                            <h5 style = {{paddingBottom: 10}}>
                                Jan 2016 - Apr 2016
                            </h5>
                            <p style = {{paddingTop: 10}}>
                                My responsibilities included <b>regulatory testing of a commercialized medical device</b> for cardiovascular procedures, <b>human factors analysis</b> of a pre-market medical device to understand <b>operator-device interactions</b> that could lead to complications during use, and <b>experimental design</b> for comparing benchmarks between simulated and real-time ageing to inform shelf-life performance. I was additionally responsible for performing <b>gap assessment for ASTM standards</b> to support quality management. This involved a detailed review of the required standards and coordinating a committee of senior engineers to determine the impact on the medical device design.
                            </p>
                            <p style = {{paddingTop: 10}}>
                                <b>SolidWorks</b> | <b>3D Printing</b> | <b>Validation & Verification Testing</b> | <b>Operational Qualification Testing</b> | <b>Performance Qualification Testing</b> | <b>ISO 13485</b>
                            </p>
                        </motion.div>
                    </Cell>                  
                </Grid>
                </div>
                
           
            )
        }
        /*RESEARCH*/
        else if(this.state.activeTab === 1){
            return(
                <div>
                <Grid className="landing" style = {{paddingLeft: '5%', paddingRight: '5%', background: '#fff'}}>
                    <Cell col={5} phone={12} tablet={12} style = {{alignSelf: 'center'}}>
                        <motion.div initial={{ opacity: 0, x: 100}}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 1 }}>
                            <img
                            //src="https://uwaterloo.ca/advanced-interface-design-lab/sites/ca.advanced-interface-design-lab/files/uploads/images/ryan.jpg"
                            src={SickKids}
                            alt="Ryan Tennant"
                            className="avatar-img"
                            />
                        </motion.div>
                    </Cell>
                    <Cell col={6} phone={12} tablet={12} style = {{alignSelf: 'center'}}>
                        <motion.div className="banner-text-white" initial={{ opacity: 0, x: -100}}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 1 }}
                            style = {{flex: 1, textAlign: 'left', marginTop: 0, marginBottom: 0}}>
                           
                                <h4 style = {{paddingBottom: 0, fontWeight: 'bold'}}>
                                    Research Assistant/Learner - Sepsis Program
                                </h4>
                                <hr style = {{height: 5, color: '#000',
                                    backgroundColor: '#000',}}>
                                </hr>
                                <a style = {{textDecoration: 'underline', color: '#000'}} href="http://www.sickkids.ca/" rel="noopener noreferrer" target="_blank">
                                    <h5 style = {{paddingBottom: 10}}>
                                        The Hospital for Sick Children
                                    </h5>
                                </a>
                                <h5 style = {{paddingBottom: 10}}>
                                    Toronto, Ontario
                                </h5>
                                <h5 style = {{paddingBottom: 10}}>
                                    January 2022 - January 2024
                                </h5>
    
                            <p style = {{paddingTop: 10}}>
                                I am currently collaborating with the Sepsis Program at SickKids to support data analysis for predicting paediatric sepsis risk. This work may inform the future development of machine learning algorithms by their team that will support patient safety by prompting clinical decision-making.
                            </p>
                            
                            <p style = {{paddingTop: 10}}>
                                <b>Paediatric Sepsis</b> | <b>Regression Analysis</b> | <b>Classification</b> | <b>Decision-Making</b>
                            </p>
                        </motion.div>
                    </Cell> 
             
                </Grid>
                <Grid className="landing" style = {{paddingLeft: '5%', paddingRight: '5%', background: '#efefef'}}>
                   <Cell col={5} phone={12} tablet={12} style = {{alignSelf: 'center'}}>
                        <motion.div initial={{ opacity: 0, x: -100}}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 1 }}>
                            <img
                            //src="https://uwaterloo.ca/advanced-interface-design-lab/sites/ca.advanced-interface-design-lab/files/uploads/images/ryan.jpg"
                            src={clinic}
                            alt="Ryan Tennant"
                            className="avatar-img-2"
                          
                            />
                        </motion.div>
                    </Cell>
                    <Cell col={6} phone={12} tablet={12} style = {{alignSelf: 'center'}}>
                        <motion.div className="banner-text-white" initial={{ opacity: 0, x: -100}}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 1 }}
                            style = {{flex: 1, textAlign: 'left', marginTop: 0, marginBottom: 0}}>
                           
                                <h4 style = {{paddingBottom: 0, fontWeight: 'bold'}}>
                                    Human Factors Research Assistant
                                </h4>
                                <hr style = {{height: 5, color: '#000',
                                    backgroundColor: '#000',}}>
                                </hr>
                                <a style = {{textDecoration: 'underline', color: '#000'}} href="http://www.kellygrindrod.com/" rel="noopener noreferrer" target="_blank">
                                    <h5 style = {{paddingBottom: 10}}>
                                        University of Waterloo - School of Pharmacy
                                    </h5>
                                </a>
                                <h5 style = {{paddingBottom: 10}}>
                                    Waterloo, Ontario
                                </h5>
                                <h5 style = {{paddingBottom: 10}}>
                                    May 2021  - January 2022
                                </h5>
    
                            <p style = {{paddingTop: 10}}>
                                I collaborated with Dr. Kelly Grindrod, Dr. Moses Tetui, and Dr. Catherine Burns on a COVID-19 mass vaccination clinic project. My responsibilities involved the <b>observation</b> and <b>analysis</b> of the vaccine roll-out from a human factors perspective at the <b>Region of Waterloo's mass vaccination clinics</b>. This consisted of coordinating visits to clinics in the Region, observing workflow and developing <b>contextual design models</b>, and writing a report on the identified strategies used among clinic personnel to coordinate clients and doses. From the observational studies, I developed and studied the usability/integration of a <b>mobile application</b> to <b>support workload, stress, and reduce dose wastage</b> at mass vaccination clinics. This work will be presented to the Region of Waterloo once results are finalized.
                            </p>
                            
                            <p style = {{paddingTop: 10}}>
                                <b>Observational Study</b> | <b>Contextual Inquiry/Design</b> | <b>Situational Awareness</b> | <b>Mobile App Development</b> | <b>Usability Engineering</b> | <b>Engineering Design</b> | <b>Interviews</b> | <b>Mixed-Methods</b> | <b>Decision-Making</b>
                            </p>
                        </motion.div>
                    </Cell> 
             
                </Grid>

                <Grid className="landing" style = {{paddingLeft: '5%', paddingRight: '5%', background: '#fff'}}>
                   <Cell col={5} phone={12} tablet={12} style = {{alignSelf: 'center'}}>
                        <motion.div initial={{ opacity: 0, x: 100}}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 1 }}>
                            <img
                            //src="https://uwaterloo.ca/advanced-interface-design-lab/sites/ca.advanced-interface-design-lab/files/uploads/images/ryan.jpg"
                            src={waterloo}
                            alt="Ryan Tennant"
                            className="avatar-img-2"
                          
                            />
                        </motion.div>
                    </Cell>
                    <Cell col={6} phone={12} tablet={12} style = {{alignSelf: 'center'}}>
                        <motion.div className="banner-text-white" initial={{ opacity: 0, x: 100}}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 1 }}
                            style = {{flex: 1, textAlign: 'left', marginTop: 0, marginBottom: 0}}>
                           
                                <h4 style = {{paddingBottom: 0, fontWeight: 'bold'}}>
                                    Human Factors Research Assistant
                                </h4>
                                <hr style = {{height: 5, color: '#000',
                                    backgroundColor: '#000',}}>
                                </hr>
                                <a style = {{textDecoration: 'underline', color: '#000'}} href="https://uwaterloo.ca/advanced-interface-design-lab/" rel="noopener noreferrer" target="_blank">
                                    <h5 style = {{paddingBottom: 10}}>
                                        University of Waterloo - Advanced Interface Design Lab (IDEaS/GDLS Project)
                                    </h5>
                                </a>
                                <h5 style = {{paddingBottom: 10}}>
                                    Waterloo, Ontario
                                </h5>
                                <h5 style = {{paddingBottom: 10}}>
                                    May 2020  - May 2021
                                </h5>
    
                            <p style = {{paddingTop: 10}}>
                                I was responsible for assisting in the development of an <b>experiment protocol</b> for a project about <b>single operator control of multiple robotic systems</b>. This consisted of reviewing and summarizing relevant literature about <b>situation awareness</b> and <b>cognitive workload</b> for interface design, as well as identifying and recommending <b>physiological measures</b> of mental workload.
                            </p>
                            
                            <p style = {{paddingTop: 10}}>
                                <b>Literature Review</b> | <b>Cognitive Workload</b> | <b>Situational Awareness</b> | <b>Physiological Measures</b>
                            </p>
                        </motion.div>
                    </Cell> 
             
                </Grid>
                                
                <Grid className="landing" style = {{paddingBottom: '0%', paddingLeft: '5%', paddingRight: '5%', background: '#efefef'}}>
                <Cell col={5} phone={12} tablet={12} style = {{alignSelf: 'center'}}>
                        <motion.div initial={{ opacity: 0, x: -100}}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 1 }}>
                            <img
                            //src="https://uwaterloo.ca/advanced-interface-design-lab/sites/ca.advanced-interface-design-lab/files/uploads/images/ryan.jpg"
                            src={waterloo}
                            alt="Ryan Tennant"
                            className="avatar-img-2"
                            />
                        </motion.div>
                    </Cell>
                    <Cell col={6} phone={12} tablet={12} style = {{alignSelf: 'center'}}>
                        <motion.div className="banner-text-white" initial={{ opacity: 0, x: -100}}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 1 }}
                            style = {{flex: 1, textAlign: 'left'}}>
                            <h4 style = {{paddingBottom: 0, fontWeight: 'bold'}}>
                                Human Factors Research Assistant
                            </h4>
                            <hr style = {{height: 5, color: '#000',
                                    backgroundColor: '#000',}}>
                            </hr>
                            <a style = {{textDecoration: 'underline', color: '#000'}} href="https://uwaterloo.ca/pharmacy/people-profiles/tejal-patel" rel="noopener noreferrer" target="_blank">
                                <h5 style = {{paddingBottom: 10}}>
                                    University of Waterloo - School of Pharmacy
                                </h5>
                            </a>
                            <h5 style = {{paddingBottom: 10}}>
                                Waterloo, Ontario
                            </h5>
                            <h5 style = {{paddingBottom: 10}}>
                                Sep 2020 - Aug 2020
                            </h5>
                            <p style = {{paddingTop: 10}}>
                                I was responsible for providing a human factors perspective for the integration of a <b>smart medication package</b> and <b>online portal</b> that is used by patients and their pharmacists for tracking medication adherence. Frameworks that were used in this research include the <b>technology acceptance model</b>, <b>system usability score</b> and the <b>comprehensive model for behavioural treatment</b>. I worked with the reasearch team to carry-out the <b>ethnographic study</b> and <b>qualitative analysis</b>. I was additionally responsible for writing scripts (Python) to collect and organize the medication adherence data from the online portal by accessing the <b>REST API</b>, as well as <b>suggesting improvements for data visualization of the user interface</b> based on usability heuristics.
                            </p>
                            <p style = {{paddingTop: 10}}>
                                <b>Ethnography</b> | <b>Qualitative Data Analysis</b> | <b>Quantitative Data Analysis</b> | <b>Python</b> | <b>JSON</b> | <b>REST API</b>
                            </p>
                        </motion.div>
                    </Cell>                  
                </Grid>

                <Grid className="landing" style = {{paddingBottom: '0%', paddingLeft: '5%', paddingRight: '5%', background: '#fff'}}>
                <Cell col={5} phone={12} tablet={12} style = {{alignSelf: 'center'}}>
                        <motion.div initial={{ opacity: 0, x: -100}}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 1 }}>
                            <img
                            //src="https://uwaterloo.ca/advanced-interface-design-lab/sites/ca.advanced-interface-design-lab/files/uploads/images/ryan.jpg"
                            src={ryan_sunny}
                            alt="Ryan Tennant"
                            className="avatar-img-2"
                            />
                        </motion.div>
                    </Cell>
                    <Cell col={6} phone={12} tablet={12} style = {{alignSelf: 'center'}}>
                        <motion.div className="banner-text-white" initial={{ opacity: 0, x: -100}}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 1 }}
                            style = {{flex: 1, textAlign: 'left'}}>
                            <h4 style = {{paddingBottom: 0, fontWeight: 'bold'}}>
                                Biomedical Engineering Research Assistant
                            </h4>
                            <hr style = {{height: 5, color: '#000',
                                    backgroundColor: '#000',}}>
                            </hr>
                            <a style = {{textDecoration: 'underline', color: '#000'}} href="https://sunnybrook.ca/research/" rel="noopener noreferrer" target="_blank">
                                <h5 style = {{paddingBottom: 10}}>
                                    Sunnybrook Research Institute
                                </h5>
                            </a>
                            <h5 style = {{paddingBottom: 10}}>
                                Toronto, Ontario
                            </h5>
                            <h5 style = {{paddingBottom: 10}}>
                                May 2017 - May 2018
                            </h5>
                            <p style = {{paddingTop: 10}}>
                                I was responsible for <b>prototyping</b> the novel coronary microcatheter that was conceptualized during the Medventions Fellowship Program. This consisted of <b>designing and testing primary functionality in the lab</b>. I worked with the Chief of Cardiology, Dr. Bradley Strauss, to develop the microcatheter from a <b>human factors</b> perspective. We <b>successfully proved the concept</b>, have acquired funding and continue to collaborate with manufacturers.
                            </p>
                            <p style = {{paddingTop: 10}}>
                                <b>SolidWorks</b> | <b>3D Printing</b> | <b>Hot Air Rework</b> | <b>Grant Writing</b>
                            </p>
                        </motion.div>
                    </Cell>                  
                </Grid>

               </div>
            )
        }

        /*EDUCATION*/
        // alignSelf={'center'} if you want the image to be aligned centered vertically
        else if(this.state.activeTab === 2){
            return(
                <div>
                <Grid className="landing" style = {{paddingLeft: '5%', paddingRight: '5%'}}>
                    <Cell col={5} phone={12} tablet={12} style={{paddingTop: '6%', paddingBottom: '6%'}}>
                        <motion.div initial={{ opacity: 0, y: -100}}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1 }}>
                            <img
                            //src="https://uwaterloo.ca/advanced-interface-design-lab/sites/ca.advanced-interface-design-lab/files/uploads/images/ryan.jpg"
                            src={masc}
                            alt="Ryan Tennant"
                            className="avatar-img-edu"
                            
                            />
                        </motion.div>
                    </Cell>
                    
                    <Cell col={6} phone={12} tablet={12} style = {{textAlign: 'left'}}>
                        <motion.div className="banner-text-white" initial={{ opacity: 0, y: -100}}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1 }}>
                            <h4>
                                <b>University of Waterloo</b>
                            </h4>
                            <h4>
                                <i>Doctor of Philosophy, PhD (student)</i>
                             </h4>
                             <hr style = {{height: 5, color: '#000',
                                    backgroundColor: '#000',}}>
                            </hr>
                             <h5>
                                May 2021 - 2024 (expected)
                             </h5>
                            <h6 style={{paddingTop: '2.5%'}}>
                                Systems Design Engineering - Biomedical Engineering
                            </h6>

                            <p style={{paddingTop: 10}}>
                                Resume/CV: <a style={{padding: '10px', color: '#2c3e50'}} href = {CVPDF} rel="noopener noreferrer" target = "_blank"><b>Download</b></a>
                            </p>

                            <p style = {{paddingTop: 10}}>
                                <b>Research Interests</b>:
                            </p>

                            <p>
                                <ul>
                                    <li>
                                        Human Factors; Human Factors Engineering; Cognitive Work Analysis
                                    </li>
                                    <li>
                                        Interface Design for Complex and Collaborative Information Systems; Mobile App Development
                                    </li>
                                    <li>
                                        Clinical Engineering for Home Care Technologies
                                    </li>
                                    <li>
                                        Human Interaction with Machine Learning; Clinical Prediction Technologies for Paediatric Sepsis
                                    </li>
                                </ul>
                            </p>
                            
                                <p style = {{paddingTop: 10}}>
                                  <p>
                                     <IconToggle ripple id="phd" name={this.state.chevronType_phd_awards} defaultChecked onClick={this.openList} />
                                     <b style={{paddingLeft: 0, marginLeft: 0}} onClick={this.openList}>Awards & Scholarships</b>:
                                     {this.awards_phd()}
                                  </p>

                                  <p>
                                    <IconToggle ripple id="phd_publications" name={this.state.chevronType_phd_publications} defaultChecked onClick={this.openList9} />
                                    <b style={{paddingLeft: 0, marginLeft: 0}} onClick={this.openList9}>Publications</b>:
                                    {this.publications_phd()}
                                    </p>

                                  <p>
                                     <IconToggle ripple id="phd_news" name={this.state.chevronType_phd_news} defaultChecked onClick={this.openList_news} />
                                     <b style={{paddingLeft: 0, marginLeft: 0}} onClick={this.openList_news}>News Articles & Media</b>:
                                     {this.news_phd()}
                                  </p>
                                </p>
                              
                            <h6 style={{paddingTop: '2.5%'}}>
                                
                            </h6>
                        </motion.div>
                    </Cell>                  
                </Grid>

                <Grid className="landing" style = {{paddingLeft: '5%', paddingRight: '5%', background: '#efefef'}}>
                    <Cell col={5} phone={12} tablet={12} style={{paddingTop: '6%', paddingBottom: '6%'}}>
                        <motion.div initial={{ opacity: 0, y: -100}}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1 }}>
                            <img
                            //src="https://uwaterloo.ca/advanced-interface-design-lab/sites/ca.advanced-interface-design-lab/files/uploads/images/ryan.jpg"
                            src={masc}
                            alt="Ryan Tennant"
                            className="avatar-img-edu"
                            
                            />
                        </motion.div>
                    </Cell>
                    
                    <Cell col={6} phone={12} tablet={12} style = {{textAlign: 'left'}}>
                        <motion.div className="banner-text-white" initial={{ opacity: 0, y: -100}}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1 }}>
                            <h4>
                                <b>University of Waterloo</b>
                             </h4>
                            <h4>
                                <i>Master of Applied Science, MASc</i>
                             </h4>
                             <hr style = {{height: 5, color: '#000',
                                    backgroundColor: '#000',}}>
                            </hr>
                             <h5>
                                September 2019 - May 2021
                             </h5>
                            <h6 style={{paddingTop: '2.5%'}}>
                                Systems Design Engineering - Biomedical Engineering
                            </h6>
                            <h6 style={{paddingTop: '1%'}}>
                                Thesis: "Supporting Caregivers in Complex Home Care: Towards Designing a Voice User Interface"
                            </h6>
                          
                           
                                <p style = {{paddingTop: 30}}>
                                    
                                    <p>
                                    <IconToggle ripple id="masc_conferences" name={this.state.chevronType_masc_conferences} defaultChecked onClick={this.openList8} />
                                    <b style={{paddingLeft: 0, marginLeft: 0}} onClick={this.openList8}>Conference Publications</b>:
                                    {this.conferences_masc()}
                                    </p>

                                    <p>
                                    <IconToggle ripple id="masc_other" name={this.state.chevronType_masc_other} defaultChecked onClick={this.openList6} />
                                    <b style={{paddingLeft: 0, marginLeft: 0}} onClick={this.openList6}>Presentations</b>:
                                    {this.other_masc()}
                                    </p>

                                    <p>
                                    <IconToggle ripple id="masc_publications" name={this.state.chevronType_masc_publications} defaultChecked onClick={this.openList7} />
                                    <b style={{paddingLeft: 0, marginLeft: 0}} onClick={this.openList7}>Publications</b>:
                                    {this.publications_masc()}
                                    </p>

                                    <p>
                                    <IconToggle ripple id="masc_awards" name={this.state.chevronType_masc_awards} defaultChecked onClick={this.openList2} />
                                    <b style={{paddingLeft: 0, marginLeft: 0}} onClick={this.openList2}>Awards & Scholarships</b>:                                  
                                    {this.awards_masc()}
                                    </p>
                                    
                                </p>
                   
                            
                              
                            <h6 style={{paddingTop: '2.5%'}}>
                                
                            </h6>
                        </motion.div>
                    </Cell>                  
                </Grid>

                <Grid className="landing" style = {{paddingLeft: '5%', paddingRight: '5%',}}>
                    <Cell col={5} phone={12} tablet={12} style={{paddingTop: '6%', paddingBottom: '6%'}}>
                        <motion.div initial={{ opacity: 0, y: -100}}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1 }}>
                            <img
                            //src="https://uwaterloo.ca/advanced-interface-design-lab/sites/ca.advanced-interface-design-lab/files/uploads/images/ryan.jpg"
                            src={basc}
                            alt="Ryan Tennant"
                            className="avatar-img-edu"
                            />
                        </motion.div>
                    </Cell>
                    
                    <Cell col={6} phone={12} tablet={12} style = {{textAlign: 'left'}}>
                        <motion.div className="banner-text-white" initial={{ opacity: 0, y: -100}}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1 }}>
                            <h4>
                                <b>University of Waterloo</b>
                             </h4>
                            <h4>
                                <i>Bachelor of Applied Science, BASc</i>
                             </h4>
                             <hr style = {{height: 5, color: '#000',
                                    backgroundColor: '#000',}}>
                            </hr>
                             <h5>
                                September 2014 - May 2019
                             </h5>
                            <h6 style={{paddingTop: '2.5%'}}>
                                Honours Biomedical Engineering - Co-operative Program (With Distinction)
                            </h6>
                            <h6 style={{paddingTop: '2.5%'}}>
                                
                            </h6>
                            <p style = {{paddingTop: 10}}>
                                
                                <p>
                                <IconToggle ripple id="basc_conf" name={this.state.chevronType_basc_conferences} defaultChecked onClick={this.openList5} />
                                <b style={{paddingLeft: 0, marginLeft: 0}} onClick={this.openList5}>Conference Publications</b>:
                                {this.conferences_basc()}
                                </p>

                                <p>     
                                <IconToggle ripple id="basc_patent" name={this.state.chevronType_basc_patents} defaultChecked onClick={this.openList4} />
                                <b style={{paddingLeft: 0, marginLeft: 0}} onClick={this.openList4}>Patents</b>:
                                {this.patents_basc()}
                                </p>

                                <p>
                                <IconToggle ripple id="basc_award" name={this.state.chevronType_basc_awards} defaultChecked onClick={this.openList3} />
                                <b style={{paddingLeft: 0, marginLeft: 0}} onClick={this.openList3}>Awards & Scholarships</b>:
                                {this.awards_basc()}
                                </p>

                            </p>
                        </motion.div>
                    </Cell>                  
                </Grid>
                </div>
            )
        }
        
        /*TEACHING*/
        //style = {{alignSelf: 'center'}}
        else if(this.state.activeTab === 3){
            return(
                <div>
                <Grid className="teaching" style = {{paddingLeft: '5%', paddingRight: '5%', background: '#efefef'}}>
                    <Cell col={5} phone={12} tablet={12}>
                        <motion.div initial={{ opacity: 0, x: -100}}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 1 }}>
                            <img
                            //src="https://uwaterloo.ca/advanced-interface-design-lab/sites/ca.advanced-interface-design-lab/files/uploads/images/ryan.jpg"
                            src={waterloo}
                            alt="Ryan Tennant"
                            className="avatar-img-2"
                            />
                        </motion.div>
                    </Cell>
                    
                    <Cell col={6} phone={12} tablet={12} style = {{textAlign: 'left'}}>
                        <motion.div className="banner-text-white" initial={{ opacity: 0, y: -100}}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1 }}>
                            <h4>
                                <b>Graduate Teaching Assistant</b>
                            </h4>
                            <hr style = {{height: 5, color: '#000',
                                    backgroundColor: '#000',}}>
                            </hr>
                            <a style = {{textDecoration: 'underline', color: '#000'}} href="https://uwaterloo.ca/systems-design-engineering/" rel="noopener noreferrer" target="_blank">
                                <h5 style = {{paddingBottom: 10}}>
                                    University of Waterloo - Department of Systems Design Engineering
                                </h5>
                            </a>
                            <h5 style = {{paddingBottom: 10}}>
                                Waterloo, Ontario
                            </h5>
                            <h5 style={{paddingBottom: 10}}>
                                Jan 2020 - Present
                            </h5 >
                            <p style = {{paddingTop: 10}}>
                                <p>
                                    <IconToggle ripple id="credentials" name={this.state.chevronType_teaching_credentials} defaultChecked onClick={this.openList_credentials} />
                                    <b style={{paddingLeft: 0, marginLeft: 0}} onClick={this.openList_credentials}>Teaching Credentials</b>:
                                    {this.teaching_credentials()}
                                </p>
                                    
                                <p>
                                    <IconToggle ripple id="courses" name={this.state.chevronType_teaching_courses} defaultChecked onClick={this.openList_courses} />
                                    <b onClick={this.openList_courses}>Courses Taught</b>:
                                    {this.teaching_courses()}
                                </p>
                            </p>
                            <h6 style={{paddingTop: '2.5%'}}>
                                
                            </h6>
                        </motion.div>
                    </Cell>                  
                </Grid>

                <Grid className="teaching" style = {{paddingLeft: '5%', paddingRight: '5%',}}>
                    <Cell col={5} phone={12} tablet={12}>
                        <motion.div initial={{ opacity: 0, x: -100}}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 1 }}>
                            <img
                            //src="https://uwaterloo.ca/advanced-interface-design-lab/sites/ca.advanced-interface-design-lab/files/uploads/images/ryan.jpg"
                            src={rangers}
                            alt="Ryan Tennant"
                            className="avatar-img-2"
                            />
                        </motion.div>
                    </Cell>
                    
                    <Cell col={6} phone={12} tablet={12} style = {{textAlign: 'left'}}>
                        <motion.div className="banner-text-white" initial={{ opacity: 0, y: -100}}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1 }}>
                            <h4>
                                <b>Highschool Math & Science Tutor</b>
                            </h4>
                            <hr style = {{height: 5, color: '#000',
                                    backgroundColor: '#000',}}>
                            </hr>
                            <a style = {{textDecoration: 'underline', color: '#000'}} href="https://kitchenerrangers.com/" rel="noopener noreferrer" target="_blank">
                                <h5 style = {{paddingBottom: 10}}>
                                    Kitchener Rangers Hockey Club (OHL)
                                </h5>
                            </a>
                            <h5 style = {{paddingBottom: 10}}>
                                Kitchener, Ontario
                            </h5>
                            <h5 style={{paddingBottom: 10}}>
                                Sep 2014 - Nov 2019
                            </h5 >
                         
                                <p style = {{paddingTop: 10}}>
                                    <IconToggle ripple id="phd" name={this.state.chevronType_teaching_courses_R} defaultChecked onClick={this.openList_courses_R} />
                                    <b onClick={this.openList_courses_R}>Courses Taught</b>:
                                    {this.teaching_courses_R()}
                                </p>
                          
                            <h6 style={{paddingTop: '2.5%'}}>
                                
                            </h6>
                        </motion.div>
                    </Cell>                  
                </Grid>

               </div>
            )
        }

        /*VOLUNTEER*/
        else if(this.state.activeTab === 4){
            return(
                <div>
                <Grid className="landing" style = {{justifyContent: 'center'}}>
                <motion.Cell initial={{ opacity: 100, x: 0, y: -100}}
                             animate={{ opacity: 1, x: 0 , y: 0}}
                             transition={{ duration: 1 }}
                                 col={4} phone={6} tablet={4} style = {{height: '300px', width: '300px', padding: '2.5%', alignSelf: 'center', backgroundColor: '#daebe8'}}>
                        <div className="banner-text-white" initial={{ opacity: 0, y: -100}}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1 }}
                            style = {{flex: 1, textAlign: 'left'}}>
                                <hr style={{
                                    color: '#000',
                                    backgroundColor: '#000',
                                    height: 5,
                                    justifyContent: 'flex-start'
                                }}>
                                </hr>
                            <h4 style = {{paddingBottom: 10, fontWeight: 'bold'}}>
                                Website, Research, and Fundraising Volunteer
                            </h4>
                            <a style = {{textDecoration: 'underline', color: '#000'}} href="https://covid19resources.ca" rel="noopener noreferrer" target="_blank">
                                <h5 style = {{paddingBottom: 10}}>
                                    COVID-19 Resources Canada
                                </h5>
                            </a>
                            <h6 style = {{paddingBottom: 10}}>
                                Canada
                            </h6>
                            <h6 style = {{paddingBottom: 10}}>
                                March 2024 - Present
                            </h6>
                        </div>
                    </motion.Cell> 
                <motion.Cell initial={{ opacity: 0, x: 100, y: 100}}
                             animate={{ opacity: 1, x: 0 , y: 0}}
                             transition={{ duration: 1 }}
                                 col={4} phone={6} tablet={4} style = {{height: '300px', width: '300px', padding: '2.5%', alignSelf: 'center', backgroundColor: '#daebe8'}}>
                        <div className="banner-text-white" initial={{ opacity: 0, y: -100}}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1 }}
                            style = {{flex: 1, textAlign: 'left'}}>
                                <hr style={{
                                    color: '#000',
                                    backgroundColor: '#000',
                                    height: 5,
                                    justifyContent: 'flex-start'
                                }}>
                                </hr>
                            <h4 style = {{paddingBottom: 10, fontWeight: 'bold'}}>
                                Spokesperson
                            </h4>
                            <a style = {{textDecoration: 'underline', color: '#000'}} href="https://ontarioschoolsafety.com" rel="noopener noreferrer" target="_blank">
                                <h5 style = {{paddingBottom: 10}}>
                                    Ontario School Safety
                                </h5>
                            </a>
                            <h6 style = {{paddingBottom: 10}}>
                                Ontario
                            </h6>
                            <h6 style = {{paddingBottom: 10}}>
                                September 2023 - Present
                            </h6>
                        </div>
                    </motion.Cell> 
                <motion.Cell initial={{ opacity: 0, x: -100, y: -100}}
                             animate={{ opacity: 1, x: 0 , y: 0}}
                             transition={{ duration: 1 }}
                                 col={4} phone={6} tablet={4} style = {{height: '300px', width: '300px', padding: '2.5%', alignSelf: 'center', backgroundColor: '#daebe8'}}>
                        <div className="banner-text-white" initial={{ opacity: 0, y: -100}}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1 }}
                            style = {{flex: 1, textAlign: 'left'}}>
                                <hr style={{
                                    color: '#000',
                                    backgroundColor: '#000',
                                    height: 5,
                                    justifyContent: 'flex-start'
                                }}>
                                </hr>
                            <h4 style = {{paddingBottom: 10, fontWeight: 'bold'}}>
                                Graduate Student Representative
                            </h4>
                            <a style = {{textDecoration: 'underline', color: '#000'}} href="https://uwaterloo.ca/engineering-faculty-council/about" rel="noopener noreferrer" target="_blank">
                                <h5 style = {{paddingBottom: 10}}>
                                    Engineering Faculty Council
                                </h5>
                            </a>
                            <h6 style = {{paddingBottom: 10}}>
                                Waterloo, Ontario
                            </h6>
                            <h6 style = {{paddingBottom: 10}}>
                                September 2022 - Present
                            </h6>
                        </div>
                    </motion.Cell> 
                    <motion.Cell initial={{ opacity: 0, x: 100, y: -100}}
                             animate={{ opacity: 1, x: 0 , y: 0}}
                             transition={{ duration: 1 }}
                             col={4} phone={4} tablet={6} style = {{height: '300px', width: '300px', padding: '2.5%', alignSelf: 'center', backgroundColor: '#87bdd8'}}>
                        <div className="banner-text-white" initial={{ opacity: 0, y: -100}}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1 }} 
                            style = {{flex: 1, textAlign: 'left'}}>
                                <hr style={{
                                    color: '#000',
                                    backgroundColor: '#000',
                                    height: 5,
                                    justifyContent: 'flex-start'
                                }}>
                                </hr>
                                <h4 style = {{paddingBottom: 10, fontWeight: 'bold'}}>
                                    Student Chapter President
                                </h4>
                                <a style = {{textDecoration: 'underline', color: '#000'}} href="https://uwaterloo.ca/human-factors-and-ergonomics-society/" rel="noopener noreferrer" target="_blank">
                                    <h5 style = {{paddingBottom: 10}}>
                                        University of Waterloo Human Factors and Ergonomics
                                    </h5>
                                </a>
                                <h6 style = {{paddingBottom: 10}}>
                                    Waterloo, Ontario
                                </h6>
                                <h6>
                                    January 2022 - December 2023
                                </h6>
                                
                        </div>
                    </motion.Cell>                        
                <motion.Cell initial={{ opacity: 0, x: -100}}
                                 animate={{ opacity: 1, x: 0}}
                                 transition={{ duration: 1 }} 
                                 col={4} phone={6} tablet={4} style = {{height: '300px', width: '300px', padding: '2.5%', alignSelf: 'center', backgroundColor: '#b7d7e8'}}>
                        <div className="banner-text-white" initial={{ opacity: 0, y: -100}}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1 }}
                            style = {{flex: 1, textAlign: 'left'}}>
                                <hr style={{
                                    color: '#000',
                                    backgroundColor: '#000',
                                    height: 5,
                                    justifyContent: 'flex-start'
                                }}>
                                </hr>
                            <h4 style = {{paddingBottom: 10, fontWeight: 'bold'}}>
                                Fundamentals Coaching Assistant
                            </h4>
                            <a style = {{textDecoration: 'underline', color: '#000'}} href="https://kwspeedskating.com/" rel="noopener noreferrer" target="_blank">
                                <h5 style = {{paddingBottom: 10}}>
                                    Kitchener-Waterloo Sertoma Speed Skating Club
                                </h5>
                            </a>
                            <h6 style = {{paddingBottom: 10}}>
                                Waterloo, Ontario
                            </h6>
                            <h6 style = {{paddingBottom: 10}}>
                                June 2022
                            </h6>
                        </div>
                    </motion.Cell> 
                    <motion.Cell initial={{ opacity: 0, x: -100, y: -100}}
                             animate={{ opacity: 1, x: 0 , y: 0}}
                             transition={{ duration: 1 }}
                             col={4} phone={4} tablet={6} style = {{height: '300px', width: '300px', padding: '2.5%', alignSelf: 'center', backgroundColor: '#87bdd8'}}>
                        <div className="banner-text-white" initial={{ opacity: 0, y: -100}}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1 }} 
                            style = {{flex: 1, textAlign: 'left'}}>
                                <hr style={{
                                    color: '#000',
                                    backgroundColor: '#000',
                                    height: 5,
                                    justifyContent: 'flex-start'
                                }}>
                                </hr>
                                <h4 style = {{paddingBottom: 10, fontWeight: 'bold'}}>
                                    Vaccine Clinic Volunteer
                                </h4>
                                <a style = {{textDecoration: 'underline', color: '#000'}} href="https://family-medicine.ca" rel="noopener noreferrer" target="_blank">
                                    <h5 style = {{paddingBottom: 10}}>
                                        The Centre for Family Medicine Family Health Team COVID Vaccine Clinic
                                    </h5>
                                </a>
                                <h6 style = {{paddingBottom: 10}}>
                                    Waterloo, Ontario
                                </h6>
                                <h6 style = {{paddingBottom: 10}}>
                                    April 2021 - August 2021
                                </h6>
                                <a style = {{textDecoration: 'underline', color: '#000'}} href="https://uwaterloo.ca/pharmacy/news/together-we-are-making-impact" rel="noopener noreferrer" target="_blank">
                                    <h6>
                                        News Article - UW School of Pharmacy
                                    </h6>
                                </a>
                                
                        </div>
                    </motion.Cell>
                <motion.Cell initial={{ opacity: 0, x: -100, y: -100}}
                             animate={{ opacity: 1, x: 0 , y: 0}}
                             transition={{ duration: 1 }}
                             col={4} phone={4} tablet={6} style = {{height: '300px', width: '300px', padding: '2.5%', alignSelf: 'center', backgroundColor: '#87bdd8'}}>
                        <div className="banner-text-white" initial={{ opacity: 0, y: -100}}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1 }} 
                            style = {{flex: 1, textAlign: 'left'}}>
                                <hr style={{
                                    color: '#000',
                                    backgroundColor: '#000',
                                    height: 5,
                                    justifyContent: 'flex-start'
                                }}>
                                </hr>
                                <h4 style = {{paddingBottom: 10, fontWeight: 'bold'}}>
                                    Student Chapter Vice-President
                                </h4>
                                <a style = {{textDecoration: 'underline', color: '#000'}} href="https://uwaterloo.ca/human-factors-and-ergonomics-society/" rel="noopener noreferrer" target="_blank">
                                    <h5 style = {{paddingBottom: 10}}>
                                        University of Waterloo Human Factors and Ergonomics
                                    </h5>
                                </a>
                                <h6 style = {{paddingBottom: 10}}>
                                    Waterloo, Ontario
                                </h6>
                                <h6>
                                    Nov 2020 - January 2022
                                </h6>
                                
                        </div>
                    </motion.Cell>
                    <motion.Cell initial={{ opacity: 0, y: -100}}
                                 animate={{ opacity: 1, y: 0 }}
                                 transition={{ duration: 1 }}
                                 col={4} phone={4} tablet={6} style = {{height: '300px', width: '300px', padding: '2.5%', alignSelf: 'center', backgroundColor: '#b7d7e8'}}>
                        <div className="banner-text-white" initial={{ opacity: 0, y: -100}}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1 }}
                            style = {{flex: 1, textAlign: 'left'}}>
                                <hr style={{
                                    color: '#000',
                                    backgroundColor: '#000',
                                    height: 5,
                                    justifyContent: 'flex-start'
                                }}>
                                </hr>
                            <h4 style = {{paddingBottom: 10, fontWeight: 'bold'}}>
                                Social Media Champion
                            </h4>
                            <a style = {{textDecoration: 'underline', color: '#000'}} href="https://www.hfes.org/" rel="noopener noreferrer" target="_blank">
                                <h5 style = {{paddingBottom: 10}}>
                                    Human Factors and Ergonomics Society - Annual Meeting
                                </h5>
                            </a>
                            <h6 style = {{paddingBottom: 10}}>
                                Virtual Conference
                            </h6>
                            <h6>
                                Oct 2020
                            </h6>
                        </div>
                    </motion.Cell>   
                    <motion.Cell initial={{ opacity: 0, x: 100, y: -100}}
                                 animate={{ opacity: 1, x: 0, y: 0 }}
                                 transition={{ duration: 1 }}
                                 col={4} phone={6} tablet={4} style = {{height: '300px', width: '300px', padding: '2.5%', alignSelf: 'center', backgroundColor: '#b7d7e8'}}>
                        <div className="banner-text-white" initial={{ opacity: 0, y: -100}}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1 }}
                            style = {{flex: 1, textAlign: 'left'}}>
                                <hr style={{
                                    color: '#000',
                                    backgroundColor: '#000',
                                    height: 5,
                                    justifyContent: 'flex-start'
                                }}>
                                </hr>
                            <h4 style = {{paddingBottom: 10, fontWeight: 'bold'}}>
                                Multi-Session Chair
                            </h4>
                            <a style = {{textDecoration: 'underline', color: '#000'}} href="https://www.hfes.org/" rel="noopener noreferrer" target="_blank">
                                <h5 style = {{paddingBottom: 10}}>
                                    Human Factors and Ergonomics in Healthcare Symposium
                                </h5>
                            </a>
                            <h6 style = {{paddingBottom: 10}}>
                                Virtual Conference
                            </h6>
                            <h6>
                                May 2020
                            </h6>
                        </div>
                    </motion.Cell>  
                    <motion.Cell initial={{ opacity: 0, x: 100}}
                                 animate={{ opacity: 1, x: 0 }}
                                 transition={{ duration: 1 }}
                                 col={4} phone={6} tablet={4} style = {{height: '300px', width: '300px', padding: '2.5%', alignSelf: 'center', backgroundColor: '#87bdd8'}}>
                            <div className="banner-text-white" initial={{ opacity: 0, y: -100}}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 1 }}
                                style = {{flex: 1, textAlign: 'left'}}>
                                    <hr style={{
                                    color: '#000',
                                    backgroundColor: '#000',
                                    height: 5,
                                    justifyContent: 'flex-start'
                                }}>
                                </hr>
                                <h4 style = {{paddingBottom: 10, fontWeight: 'bold'}}>
                                    Engineering Student Representative
                                </h4>
                                <a style = {{textDecoration: 'underline', color: '#000'}} href="https://uwaterloo.ca/graduate-studies-endowment-fund/" rel="noopener noreferrer" target="_blank">
                                    <h5 style = {{paddingBottom: 10}}>
                                        Graduate Studies Endowment Fund - Project Review Committee
                                    </h5>
                                </a>
                                <h6 style = {{paddingBottom: 10}}>
                                    Waterloo, Ontario
                                </h6>
                                <h6 style = {{paddingBottom: 10}}>
                                    Jan 2019 - April 2022
                                </h6>
                            </div>
                        </motion.Cell>
              
                                
                    <motion.Cell initial={{ opacity: 0, x: -100}}
                                 animate={{ opacity: 1, x: 0 }}
                                 transition={{ duration: 1 }}
                                 col={4} phone={6} tablet={4} style = {{height: '300px', width: '300px', padding: '2.5%', alignSelf: 'center', backgroundColor: '#87bdd8'}}>
                            <div className="banner-text-white" initial={{ opacity: 0, y: -100}}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 1 }}
                                style = {{flex: 1, textAlign: 'left'}}>
                                    <hr style={{
                                    color: '#000',
                                    backgroundColor: '#000',
                                    height: 5,
                                    justifyContent: 'flex-start'
                                }}>
                                </hr>
                                <h4 style = {{paddingBottom: 10, fontWeight: 'bold'}}>
                                    Congregate Dining Server
                                </h4>
                                <a style = {{textDecoration: 'underline', color: '#000'}} href="http://www.communitycareconcepts.ca/" rel="noopener noreferrer" target="_blank">
                                    <h5 style = {{paddingBottom: 10}}>
                                        Community Care Concepts
                                    </h5>
                                </a>
                                <h6 style = {{paddingBottom: 10}}>
                                    Woolwich, Wellesley and Wilmot, Ontario
                                </h6>
                                <h6 style = {{paddingBottom: 10}}>
                                    May 2019 - Jan 2020
                                </h6>
                            </div>
                    </motion.Cell>
                    <motion.Cell initial={{ opacity: 0, x: -100}}
                                 animate={{ opacity: 1, x: 0}}
                                 transition={{ duration: 1 }} 
                                 col={4} phone={6} tablet={4} style = {{height: '300px', width: '300px', padding: '2.5%', alignSelf: 'center', backgroundColor: '#b7d7e8'}}>
                        <div className="banner-text-white" initial={{ opacity: 0, y: -100}}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1 }}
                            style = {{flex: 1, textAlign: 'left'}}>
                                <hr style={{
                                    color: '#000',
                                    backgroundColor: '#000',
                                    height: 5,
                                    justifyContent: 'flex-start'
                                }}>
                                </hr>
                            <h4 style = {{paddingBottom: 10, fontWeight: 'bold'}}>
                                Timer Recorder
                            </h4>
                            <a style = {{textDecoration: 'underline', color: '#000'}} href="https://kwspeedskating.com/" rel="noopener noreferrer" target="_blank">
                                <h5 style = {{paddingBottom: 10}}>
                                    Kitchener-Waterloo Sertoma Speed Skating Club
                                </h5>
                            </a>
                            <h6 style = {{paddingBottom: 10}}>
                                Waterloo, Ontario
                            </h6>
                            <h6 style = {{paddingBottom: 10}}>
                                Oct 2019
                            </h6>
                        </div>
                    </motion.Cell> 
                    <motion.Cell initial={{ opacity: 0, x: 100}}
                                 animate={{ opacity: 1, x: 0 }}
                                 transition={{ duration: 1 }}
                                 col={4} phone={6} tablet={4} style = {{height: '300px', width: '300px', padding: '2.5%', alignSelf: 'center', backgroundColor: '#b7d7e8'}}>
                        <div className="banner-text-white" initial={{ opacity: 0, y: -100}}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1 }}
                            style = {{flex: 1, textAlign: 'left'}}>
                                <hr style={{
                                    color: '#000',
                                    backgroundColor: '#000',
                                    height: 5,
                                    justifyContent: 'flex-start'
                                }}>
                                </hr>
                            <h4 style = {{paddingBottom: 10, fontWeight: 'bold'}}>
                                Track Steward
                            </h4>
                            <a style = {{textDecoration: 'underline', color: '#000'}} href="https://kwspeedskating.com/" rel="noopener noreferrer" target="_blank">
                                <h5 style = {{paddingBottom: 10}}>
                                    Kitchner-Waterloo Sertoma Speed Skating Club
                                </h5>
                            </a>
                            <h6 style = {{paddingBottom: 10}}>
                                Waterloo, Ontario
                            </h6>
                            <h6 style = {{paddingBottom: 10}}>
                                Oct 2018
                            </h6>
                        </div>
                    </motion.Cell>   
                    <motion.Cell initial={{ opacity: 0, y: 100, x: -100}}
                                 animate={{ opacity: 1, y: 0, x: 0}}
                                 transition={{ duration: 1 }}
                                 col={4} phone={6} tablet={4} style = {{height: '300px', width: '300px', padding: '2.5%', alignSelf: 'center', backgroundColor: '#b7d7e8'}}>
                        <div className="banner-text-white" initial={{ opacity: 0, y: -100}}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1 }}
                            style = {{flex: 1, textAlign: 'left'}}>
                                <hr style={{
                                    color: '#000',
                                    backgroundColor: '#000',
                                    height: 5,
                                    justifyContent: 'flex-start'
                                }}>
                                </hr>
                            <h4 style = {{paddingBottom: 10, fontWeight: 'bold'}}>
                                Orientation Leader
                            </h4>
                            <a style = {{textDecoration: 'underline', color: '#000'}} href="https://engorientation.uwaterloo.ca/#page-top" rel="noopener noreferrer" target="_blank">
                                <h5 style = {{paddingBottom: 10}}>
                                    University of Waterloo - Faculty of Engineering
                                </h5>
                            </a>
                            <h6 style = {{paddingBottom: 10}}>
                                Waterloo, Ontario
                            </h6>
                            <h6 style = {{paddingBottom: 10}}>
                                Sep 2015
                            </h6>
                            </div>
                    </motion.Cell>   
                    <motion.Cell initial={{ opacity: 0, y: 100}}
                                 animate={{ opacity: 1, y: 0 }}
                                 transition={{ duration: 1 }} 
                                 col={4} phone={6} tablet={4} style = {{height: '300px', width: '300px', padding: '2.5%', alignSelf: 'center', backgroundColor: '#87bdd8'}}>
                        <div className="banner-text-white" initial={{ opacity: 0, y: -100}}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1 }}
                            style = {{flex: 1, textAlign: 'left'}}>
                                <hr style={{
                                    color: '#000',
                                    backgroundColor: '#000',
                                    height: 5,
                                    justifyContent: 'flex-start'
                                }}>
                                </hr>
                            <h4 style = {{paddingBottom: 10, fontWeight: 'bold'}}>
                                Hospital Greeter/Porter
                            </h4>
                            <a style = {{textDecoration: 'underline', color: '#000'}} href="https://www.grhosp.on.ca/" rel="noopener noreferrer" target="_blank">
                                <h5 style = {{paddingBottom: 10}}>
                                    Grand River Hospital - Freeport Site
                                </h5>
                            </a>
                            <h6 style = {{paddingBottom: 10}}>
                                Kitchener, Ontario
                            </h6>
                            <h6 style = {{paddingBottom: 10}}>
                                Jan 2013 - Jul 2014
                            </h6>
                         </div>
                    </motion.Cell> 
                    <motion.Cell initial={{ opacity: 0, y: 100, x: 100}}
                                 animate={{ opacity: 1, y: 0, x: 0 }}
                                 transition={{ duration: 1 }}
                                 col={4} phone={6} tablet={4} style = {{height: '300px', width: '300px', padding: '2.5%', alignSelf: 'center', backgroundColor: '#87bdd8'}}>
                        <div className="banner-text-white" initial={{ opacity: 0, y: -100}}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1 }}
                            style = {{flex: 1, textAlign: 'left'}}>
                                <hr style={{
                                    color: '#000',
                                    backgroundColor: '#000',
                                    height: 5,
                                    justifyContent: 'flex-start'
                                }}>
                                </hr>
                            <h4 style = {{paddingBottom: 10, fontWeight: 'bold'}}>
                                Hockey Instructor
                            </h4>
                            <a style = {{textDecoration: 'underline', color: '#000'}} href="https://kitchenerminorhockey.com/" rel="noopener noreferrer" target="_blank">
                                <h5 style = {{paddingBottom: 10}}>
                                    Kitchener's Fundamentals Hockey School
                                </h5>
                            </a>
                            <h6 style = {{paddingBottom: 10}}>
                                Kitchener, Ontario
                            </h6>
                            <h6 style = {{paddingBottom: 10}}>
                                Oct 2008 - Mar 2014
                            </h6>
                        </div>
                    </motion.Cell> 
                                    
                </Grid>
                </div>
            )
        }
    }

    render() {
        return(
            <div className="category-tabs">
                <div className="demo-tabs">
                    <Tabs style = {{textAlign: 'center'}} activeTab={this.state.activeTab} onChange={(tabId) => this.setState({ activeTab: tabId })} ripple>
                        <Tab style={{width: '20%', padding: 0, fontSize: 13, fontWeight: 'bold'}}>Internship</Tab>
                        <Tab style={{width: '20%', padding: 0, fontSize: 13, fontWeight: 'bold'}}>Research</Tab>
                        <Tab style={{width: '20%', padding: 0, fontSize: 13, fontWeight: 'bold'}}>Education</Tab>
                        <Tab style={{width: '20%', padding: 0, fontSize: 13, fontWeight: 'bold'}}>Teaching</Tab>
                        <Tab style={{width: '20%', padding: 0, fontSize: 13, fontWeight: 'bold'}}>Volunteer</Tab>
                    </Tabs>
                    <section>
                        {this.toggleCategories()}
                    </section>
                </div>    
            </div>
        )
    }
}

export default Resume;