import React, { Component } from 'react';
import {Grid} from 'react-mdl';
import {motion} from 'framer-motion';
import CVPDF from '../assets/CV_Ryan-Tennant_06-12-2024.pdf';

class Contact extends Component {
    render() {
        return(
            <div>
                <Grid className="landing-grid" style = {{justifyContent: 'center', padding: '5%', alginSelf: 'center'}}>       
                    <motion.div initial={{ opacity: 0, y: -100}}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1 }} className="banner-text">
                        <h1>
                            Let's connect!
                        </h1> 
                        <div className="social-links" style={{paddingBottom: '5%'}}>
                            {/* Twitter */}
                            <a href="https://twitter.com/ryantennant_" rel="noopener noreferrer" target="_blank">
                            <i className="fa fa-twitter-square" aria-hidden="true"></i>
                            </a>
                            {/* Email */}
                            <a href="mailto:ryan.tennant@uwaterloo.ca" rel="noopener noreferrer" target="_blank">
                            <i class="fa fa-envelope" aria-hidden="true"></i>
                            </a>
                            {/* LinkedIn */}
                            <a href="https://www.linkedin.com/in/ryantennant/" rel="noopener noreferrer" target="_blank">
                            <i className="fa fa-linkedin-square" aria-hidden="true"></i>
                            </a>
                        </div>
                        <a style={{padding: '10px', color: '#fff'}} href = {CVPDF} rel="noopener noreferrer" target = "_blank"><b>Download Resume/CV</b></a>
                    </motion.div>        
                </Grid>
                <Grid className="landing-grid-picture">       
                        <div style={{height: '500px'}}>
                        
                        </div> 
                </Grid>
            </div>
        )
    }
}

export default Contact;