import React, { Component } from 'react';
import { Grid, Cell } from 'react-mdl';
import ryan3 from '../assets/ryan-headshot-2022.png';
import ryan4 from '../assets/ryan-speaking.jpg';
import ryan5 from '../assets/ryan-drawing.png';
import ryan6 from '../assets/ryan-hiking.jpg';
import CVPDF from '../assets/CV_Ryan-Tennant_06-12-2024.pdf';
//import { useInView } from 'react-intersection-observer';

//import ScrollAnimation from 'react-animate-on-scroll';

import { motion } from 'framer-motion';

class Landing extends Component {
    
    render() {
       
        return(
            <div style={{width: '100%', margin: 'auto'}}>
                <div>
                <Grid className="landing-grid" style = {{paddingBottom: '5%', paddingTop: '5%'}}>
                    <Cell col={6} phone={12} tablet={12} style = {{alignSelf: 'center'}}>
                        <motion.div initial={{ opacity: 0, y: -100}}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1 }}>
                            <img
                            //src="https://uwaterloo.ca/advanced-interface-design-lab/sites/ca.advanced-interface-design-lab/files/uploads/images/ryan.jpg"
                            src={ryan3}
                            alt="Ryan Tennant"
                            className="avatar-img-landing"
                            />
                        </motion.div>
                    </Cell>
                    
                    <Cell col={6} phone={12} tablet={12} style = {{alignSelf: 'center', textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}>
                        <motion.div className="banner-text" initial={{ opacity: 0, y: -100}}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1 }}>
                            <h1>
                                Hi, I'm Ryan!
                             </h1>
                            <p>
                                I'm a <b>Systems Design Engineering PhD Candidate</b>
                            </p>
                            <p>
                                at the <b>University of Waterloo</b>.
                            </p>
                        </motion.div>
                    </Cell>                  
                </Grid>
                </div>

                <div>
                <Grid className="landing" style = {{paddingBottom: '5%', paddingTop: '5%'}}>
                <Cell col={6} phone={12} tablet={12} style = {{alignSelf: 'center'}}>
                        <motion.div
                            className="banner-text-white"
                            initial={{ opacity: 0, x: -100}}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ delay: 1, duration: 1 }}>
                            
                            <h4>
                                I'm passionate about
                            </h4> 

                            <h3>
                                <b>human factors and usability</b>
                            </h3>

                            <h4>
                                for the design of
                            </h4>

                            <h3>
                                <b>healthcare technology</b>
                            </h3>
                        </motion.div>
                    </Cell>

                    <Cell col={6} phone={12} tablet={12} style = {{alignSelf: 'center'}}>
                        <motion.div initial={{ opacity: 0, x: -100 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ delay: 1, duration: 1 }}><img
                        src={ryan4}
                        alt="Ryan Tennant"
                        className="avatar-img-landing"
                        /></motion.div>
                    </Cell>
                </Grid>
                </div>

                <div>
                <Grid className="landing-grid" style = {{paddingBottom: '5%', paddingTop: '5%'}}>
                    <Cell col={6} phone={12} tablet={12} style = {{alignSelf: 'center'}}>
                        <motion.div initial={{ opacity: 0, x: 100}}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ delay: 1, duration: 1 }}>
                        <img
                        //src="https://uwaterloo.ca/advanced-interface-design-lab/sites/ca.advanced-interface-design-lab/files/uploads/images/ryan.jpg"
                        src={ryan5}
                        alt="Ryan Tennant"
                        className="avatar-img-landing"
                        />
                        </motion.div>
                    </Cell>
                    
                    <Cell col={6} phone={12} tablet={12} style = {{alignSelf: 'center'}}>
                        <motion.div initial={{ opacity: 0, x: 100}}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ delay: 1, duration: 1 }}
                            className="banner-text">
                            <h4>
                                and I use a mixed-methods approach to
                            </h4> 

                            <h3>
                                build excellence
                            </h3>

                            <h4>
                                into
                            </h4>

                            <h3>
                                every innovation.
                            </h3>
                        </motion.div>
                    </Cell>                  
                </Grid>
                </div>

                <div>
                <Grid className="landing" style = {{paddingBottom: '5%', paddingTop: '5%'}}>
                <Cell col={6} phone={12} tablet={12} style = {{alignSelf: 'center'}}>
                        <motion.div
                            className="banner-text-white"
                            initial={{ opacity: 0, x: -100}}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ delay: 1, duration: 1 }}>
                            <h4>
                                I see what needs to be done and never lose sight of purpose:
                            </h4> 
                            <h3>
                                <b>to improve the world around me.</b>
                            </h3>
                        </motion.div>
                    </Cell>

                    <Cell col={6} phone={12} tablet={12} style = {{alignSelf: 'center'}}>
                        <motion.div
                            initial={{ opacity: 0, x: -100}}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ delay: 1, duration: 1 }}>
                        <img
                        src={ryan6}
                        alt="Ryan Tennant"
                        className="avatar-img-landing"
                        />
                        </motion.div>
                    </Cell>
                </Grid>
                </div>

                <div><Grid className="landing-grid" style = {{justifyContent: 'center', paddingBottom: '5%', paddingTop: '5%', alginSelf: 'center'}}>
                    
                    
                    
                        <motion.div initial={{ opacity: 0, y: 100}}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 1, duration: 1 }} className="banner-text">
                            <h1>
                                Let's connect!
                            </h1> 
                            <div className="social-links">
                                {/* Twitter */}
                                <a href="https://twitter.com/ryantennant_" rel="noopener noreferrer" target="_blank">
                                <i className="fa fa-twitter-square" aria-hidden="true"></i>
                                </a>
                                 {/* Email */}
                                 <a href="mailto:ryan.tennant@uwaterloo.ca" rel="noopener noreferrer" target="_blank">
                                <i class="fa fa-envelope" aria-hidden="true"></i>
                                </a>
                                {/* LinkedIn */}
                                <a href="https://www.linkedin.com/in/ryantennant/" rel="noopener noreferrer" target="_blank">
                                <i className="fa fa-linkedin-square" aria-hidden="true"></i>
                                </a>
                                {/* Mastodon */}
                                <a rel="me" href="https://mstdn.ca/@ryantennant">
                                <i className="fa-brands fa-mastodon" aria-hidden="true"></i>
                                </a>
                               
                            </div>
                            <a style={{padding: '10px', color: '#fff'}} href = {CVPDF} rel="noopener noreferrer" target = "_blank"><b>Download Resume/CV</b></a>

                        </motion.div>
                                 
                </Grid>
                </div>

                <div>
                <Grid className="landing-grid" style = {{justifyContent: 'center'}}>                      
                                  
                </Grid>
                </div>

                
            </div>
        )
    }
}
export default Landing;